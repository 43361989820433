@import '../../../../variables';

.MovieScenes {
  display: flex;
  flex-wrap: wrap;
  gap: 0.8em 0.5em;
  margin: 1em 0;

  @media (max-width: $breakpoint-mobile) {
    padding: 0 0.5em;
  }

  .Scene {
    border-radius: 5px;
    background-image: linear-gradient(270deg, #000000, #191919);
    width: calc(25% - 11px);

    @media (max-width: $breakpoint-tablet) {
      width: calc(50% - 5px);
    }

    @media (max-width: $breakpoint-mobile) {
      width: 100%;
    }

    .ImageContainer {
      display: block;

      img {
        max-height: 10em;
        object-fit: cover;

        @media (max-width: $breakpoint-mobile) {
          max-height: 12em;
        }
      }
    }

    .SlideDetails {
      color: $white;
      text-transform: uppercase;
      padding: 3px 15px 6px;

      .SceneNumbering {
        display: flex;

        .Duration {
          margin-left: 5px;
          font-size: 85%;
          opacity: 0.7;
        }
      }

      .Stars {
        opacity: 0.8;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
    }

    > .ImageLoadingSkeleton {
      min-height: 330px;
    }

    .TT-Item {
      color: $white;
      text-transform: none;

      &:hover {
        opacity: 0.5;
      }

      &:not(:last-of-type)::after {
        content: ',';
      }
    }

    .TT-ToggleBtn {
      color: $white;
    }
  }
}
