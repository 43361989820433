@import '../../variables';

.ModalDialog {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: $default-dark-blue;
  border-radius: 0;
  position: absolute;
  top: 60px;
  left: 0;
  width: 100vw;
  height: 300px;
  font-size: 1.4vw;
  transform: translate3d(0, 0, 0);
  max-height: unset !important;
  user-select: none;

  .ModalDialog-primary {
    flex: 1;
    min-width: 0;
    min-height: 0;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    overflow: auto;

    .ModalDialog-secondary {
      display: flex;
      flex-direction: column;
      align-items: center;
      flex: 1;
      min-width: 0;
    }
  }

  .Row {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .Column {
    display: flex;
    flex-direction: column;

    &.Start {
      justify-content: flex-start;
    }

    &.Center {
      align-items: center;
    }

    &.Stretch {
      align-items: stretch;
    }
  }

  .ModalDialog-title {
    position: absolute;
    left: 20px;
    top: 20px;
    color: $white;
  }

  i.fa-times-circle {
    display: block;
    color: $gray;
    font-size: 1.5em;
    cursor: pointer;
    position: absolute;
    right: 10px;
    top: 10px;

    &:hover {
      color: $white;
    }
  }

  @media (min-width: $breakpoint-tablet) {
    top: 110px;
    left: 10px;
    width: calc(100vw - 35px);
    height: 500px;
    border-radius: 5px;
    box-shadow:
      0 10px 20px rgba(0, 0, 0, 0.19),
      0 6px 6px rgba(0, 0, 0, 0.23);
  }
}
