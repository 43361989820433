@import '../../variables';

.ShowMeSortByNavigation {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 20px;
  border-radius: 10px;
  text-transform: uppercase;
  background-color: $black;
  font-size: 90%;
  font-weight: bold;

  .Section {
    display: flex;
    align-items: center;

    .Label {
      color: $secondary;
      margin-right: 10px;
    }

    .Separator {
      height: 1em;
      border-left: 2px solid $white;
      margin: 0 0.5em;
      opacity: 0.5;
    }

    .Item {
      color: $gray;
      cursor: pointer;

      &.Selected {
        color: $white;
        cursor: not-allowed;
      }

      &:hover:not(.Selected) {
        color: $white;
      }
    }

    &.SeparatorsHidden {
      .Item {
        margin-left: 10px;

        &:last-of-type {
          margin-right: 0;
        }
      }
    }
  }
}
