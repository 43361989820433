@import '../../variables';

.LoadingMask {
  position: fixed;
  width: 100%;
  height: 100%;
  display: none;
  top: 0;
  background-color: lightgray;
  opacity: 0.7;
  justify-content: center;
  align-items: center;
  z-index: 3000;

  &.Visible {
    display: flex;
  }

  svg {
    fill: $dark-blue;
  }
}
