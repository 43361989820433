@import '../../../../variables';

.MyAccountUserInfos {
  display: flex;
  align-items: center;

  @media (max-width: $breakpoint-tablet) {
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
  }

  .UserEmail {
    color: $white;
    opacity: 0.8;
  }

  .Separator {
    border-left: 1px solid $white;
    margin: 5px 10px 5px;
    height: 1em;
  }

  .Options {
    display: flex;
    flex-direction: row;
  }

  .UserAccountLink {
    color: $white;
    font-weight: 400;
    text-decoration: underline;
    cursor: pointer;

    &:hover {
      color: $gray;
    }
  }

  @media (min-width: $breakpoint-tablet) {
    .UserEmail {
      font-size: 24px;
    }
  }

  @media (min-width: 1200px) {
    .UserEmail {
      font-size: 24px;
    }
  }
}

.MyAccountSignInModal {
  .MemberPWReminder {
    margin: 0;
    padding: 0;
    background: unset;
    line-height: 1;
    max-width: unset;
    color: $white;
    
    &>span {
      font-size: 70%;
    }
  }
}