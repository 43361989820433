@import '../../variables';

.MemberPWReminder {
  max-width: 20em;
  margin: 2em auto;
  background: #ddd;
  padding: 1em;
  text-align: center;

  .SupportLink {
    cursor: pointer;

    &:hover {
      color: $secondary;
    }
  }

  form {
    margin-top: .8em;
  }
} 