@import '../../variables';

.ApplicationLogo {
  background: $white;
  display: flex;
  align-items: center;
  justify-content: center;
  max-height: 100px;
  max-width: 350px;

  svg { 
    width: 100vw;
    height: auto;
    max-width: 12em;
    vertical-align: middle; 
    pointer-events: none;
    margin: 0 auto;

    @media (max-width: $breakpoint-mobile) {
      max-width: 13em;
    }

    &.DesktopIcon {
      display: none;
    }

    &.MobileIcon {
      display: block; 
    } 

    @media (min-width: $breakpoint-tablet) {  
      max-width: 16em;

      &.DesktopIcon {
        display: block;
      }

      &.MobileIcon {
        display: none;
      } 
    }
  }
}
