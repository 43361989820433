@import '../../../../variables';

.StarsLetterCatalogDisplay {
  padding: 10px;
  font-family: $font-family-metropolis;

  .StarsLetterCatalogDisplay-title {
    font-size: $fontSizeTitleWeb;
    color: $primary;
    text-transform: uppercase;

    @media (max-width: $breakpoint-mobile) {
      font-size: $fontSizeTitleMobile;
    }
  }

  .AlphabeticNavigation {
    background-color: $primary;
    margin: 30px 0;
  }

  .PageIndicator {
    margin: 0 auto;
  }

  .ShowMeSortByNavigation {
    border-radius: 5px;
    justify-content: center;
    margin: 30px 0;
    font-family: $font-family-oswald;

    .Label {
      color: $secondary;
      display: none;
    }

    .Item {
      margin-right: 10px;

      &:last-of-type {
        margin-right: 0;
      }
    }
  }

  .StarsLetterCatalogDisplay-namesList {
    padding: 40px;

    @media (min-width: $breakpoint-mobile) {
      column-count: 2;
    }

    @media (min-width: $breakpoint-tablet) {
      column-count: 3;
    }

    .StarsLetterCatalogDisplay-star {
      display: block;
      color: $dark-black;
      padding: 3px 0;

      &:hover {
        opacity: 0.5;
      }
    }

    .ImageLoadingSkeleton {
      height: 24px;
      margin-bottom: 5px;
    }
  }

  @media (min-width: $breakpoint-tablet) {
    padding: 30px 30px 10px;

    .ShowMeSortByNavigation {
      .Label {
        display: block;
      }
    }
  }
}
