@import '../../variables';

.MoviesScenesGrid {
  padding: 0 1em;

  @media (max-width: $breakpoint-mobile) {
    padding: 0 0.5em;
  }

  .ShowMeSortByNavigation {
    margin-bottom: 1em;
  }

  .Grid,
  .PlaceholdersGrid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(330px, 1fr));
    gap: 0.8em;

    @media (min-width: 1600px) {
      grid-template-columns: repeat(auto-fill, minmax(500px, 1fr));
    }

    .BoxCoverInfoDisplay {
      width: auto;
    }

    &.Movies {
      grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));

      @media (min-width: 1600px) {
        grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));
      }

      @media (max-width: $breakpoint-mobile) {
        grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
      }

      .ImagePlaceholder {
        height: 14em;
      }
    }
  }

  .PlaceholdersGrid {
    .ImagePlaceholder {
      height: 8em;
      margin-bottom: 0.3em;
    }

    .TextPlaceholder {
      height: 1em;
      width: 50%;
    }
  }

  .Grid,
  .PlaceholdersGrid {
    margin: 1em 0;
  } 
 
  .PageSubTitle { 
    font-size: 90%;
  } 

  .Description {
    margin-bottom: 1em;
    font-size: 90%;
    opacity: 0.8;
    font-family: $font-family-metropolis;
  }

  .NumberIndicator {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    pointer-events: none;
  }

  .NewTodatSticker {
    position: absolute;
    top: 0;
    left: 0;
    height: 80px;
    z-index: 1;
    pointer-events: none;
  }
}
