@import '../../../variables';

.Downloads {
  position: relative;
  width: 35px;
  height: 30px;
  text-align: center;

  @media (min-width: $breakpoint-tablet) {
    &:hover .DownloadsOptionsTable {
      display: table;
    }
  }

  .DownloadsOptionsTable {
    color: #fff;
    position: absolute;
    z-index: 5;
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
    background: rgba(0, 0, 0, 0.7);
    border: 1px solid rgba(255, 255, 255, 0.4);
    white-space: nowrap;
    border-collapse: collapse;
    text-align: left;
    display: none;

    @media (max-width: $breakpoint-tablet) {
      & {
        right: 100%;
        left: unset;
        transform: translateX(0);
        transform: translateY(50%);
      }
    }

    td,
    th {
      padding: 3px 10px;

      @media (max-width: $breakpoint-tablet) {
        padding: 6px 10px;
      }
    }

    thead {
      text-align: left;
    }

    .DownloadsOptionsTableItem {
      &:hover {
        opacity: 0.7;
      }
    }
  }

  .DownloadIcon {
    display: inline-block;
    text-align: center;
    color: $videoDetailstItems;

    @media (max-width: $breakpoint-tablet) {
      & svg {
        display: inline-block;
        pointer-events: none;
      }
    }
  }

  .DownloadSpinner {
    font-size: 25px;
    color: $videoDetailstItems;
  }
}
