@import '../../variables';

.MainDashboard {
  display: flex;
  flex-direction: column;
  height: 100%;

  .MainContent {
    overflow: auto;

    @media (max-width: $breakpoint-mobile) {
      margin-bottom: 2em;
    }

    .MobileBottomUserButtons {
      position: fixed;
      bottom: 0;
      left: 0;
      z-index: 2;
      width: 100%;
    }
  }

  .Notifications {
    top: 105px;
  }

  .ModalDialog {
    &.Center {
      left: 0;
    }
  }

  @media (min-width: $breakpoint-tablet) {
    background-color: $white;

    .ModalDialog {
      &.Center {
        top: 120px;
        left: 40vw;
      }
    }
  }
}