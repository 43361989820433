@import '../../../variables';

.NotificationComponent {
  padding: 15px 20px 20px;
  display: flex;
  align-items: center;
  background-color: $white;
  border-radius: 3px;
  box-sizing: border-box;
  color: rgba(0, 0, 0, 0.65);
  width: 384px;
  margin-bottom: 5px;
  box-shadow:
    0 10px 20px rgba(0, 0, 0, 0.19),
    0 6px 6px rgba(0, 0, 0, 0.23);
  position: relative;
  animation-name: NotificationLeftFadeIn;
  animation-duration: 0.24s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: both;

  .TypeIcon {
    .fa-info-circle,
    .fa-check-circle,
    .fa-times-circle {
      margin-right: 10px;
      font-size: 30px;
    }

    .fa-info-circle {
      color: $light-blue;
    }

    .fa-check-circle {
      color: $light-green;
    }

    .fa-times-circle {
      color: $light-red;
    }
  }

  .Title {
    font-size: 24px;
  }

  .fa-times {
    font-size: 20px;
    color: $gray;
    opacity: 0.45;
    cursor: pointer;

    &:hover {
      opacity: 1;
    }
  }

  .Message {
    font-size: 18px;
  }

  .Column {
    &.Fill {
      flex: 1;
      min-width: 0;
    }
  }

  &.Mounted {
    right: 24px;
    @media (max-width: $breakpoint-mobile) {
      right: 0;
      font-size: 16px;
      width: 320px;
    }
  }

  &.Closing {
    animation-name: NotificationFadeOut;
  }

  @keyframes NotificationLeftFadeIn {
    0% {
      right: 24px;
      opacity: 0;
    }
    100% {
      right: 24px;
      opacity: 1;
    }
  }

  @media (max-width: $breakpoint-mobile) {
    @keyframes NotificationLeftFadeIn {
      0% {
        right: 0;
        opacity: 0;
      }
      100% {
        right: 0;
        opacity: 1;
      }
    }
  }

  @keyframes NotificationFadeOut {
    0% {
      max-height: 150px;
      margin-bottom: 16px;
      padding-top: 15px;
      padding-bottom: 20px;
      opacity: 1;
    }
    100% {
      max-height: 0;
      margin-bottom: 0;
      padding-top: 0;
      padding-bottom: 0;
      opacity: 0;
    }
  }
}
