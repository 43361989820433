@import '../../../variables';

.AlphabeticViewLoadingSkeleton {
  .Letter {
    color: $dark-blue;
    font-weight: bold;
    font-size: 1.7em;
    margin-bottom: 20px;
  }

  .LoadingSkeleton {
    height: 25px;
    margin: 0 0 10px;
    background-color: #ddd;
  }
}
