@import '../../../variables';

.MemberDealsPage {
  padding: 1em;

  & * {
    box-sizing: border-box;
  }
}

.MemberDealsSection {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  gap: .5em;

  & * {
    box-sizing: border-box;
  }

  .MemberDealsImport {
    flex: 1 0 30%;

    @media (max-width: $breakpoint-tablet) {
      flex: 1 0 33%;
    }

    &:hover {
      opacity: .8;
    }

    img {
      width: 100%;
      border: solid 1px #cccccc;
      padding: .5em;
    }
  }
}

.MemberDealsBgOverlay {
  background: rgba(39, 39, 39, 0.85);
  position: fixed;
  inset: 0;
  display: flex;
  align-items: center;

  .MemberDealsModal {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    max-width: 80vw;
    max-height: 80vh;
    overflow: hidden;
    margin: 3.5em auto 0;
    background: $background;
    text-align: center;
    text-transform: uppercase;
    font-family: $font-family-metropolis;
    font-size: 120%;

    @media (max-width: $breakpoint-tablet) {
      font-size: 80%;
    }

    .MemberDealsSection {
      overflow: auto;
      padding: 0 .5em;
    }

    .dealsModalHeader {
      color: $secondary;
      padding: .8em 0;
    }

    .DealsModalAccount {
      background: #ffffff;
      color: $primary;
      padding: .8em 0;
      font-weight: bold;
    }

    .MemberDealsModalCLoseBtn {
      display: block;
      color: #96989a;
      font-size: 120%;
      width: 1em;
      height: 1em;
      cursor: pointer;
      position: absolute;
      right: 5px;
      top: 5px;
      z-index: 1;
      background: #ffffff;
      border: 1px solid #ffffff;
      border-radius: 50%;
      padding: 0;
    }

    .MemberDealsModalSeeMoreBtn {
      background: $secondary;
      color: $background;
      border-radius: 18px;
      padding: 10px 40px;
      letter-spacing: -1px;
      font-size: inherit;
      max-width: 15em;
      margin: .5em auto;

      &:hover {
        opacity: .8;
      }
    }
  }
}