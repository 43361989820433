@import '../../../variables';

.SideMenu {
  z-index: 900;
  position: absolute;
  top: 75px;
  right: -500px;
  width: 0;
  transition: 0.1s;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  background-color: rgba(248, 246, 247, 0.9);
  overflow: auto;

  .Section {
    min-width: 200px;
    text-align: right;
    margin: 0 40px;

    .Entry {
      cursor: pointer;
      color: $black;
      margin-left: 20px;
      margin-top: 5px;
      font-size: 20px;
      text-transform: uppercase;
      display: block;

      &:first-child {
        margin-top: 0;
      } 
    }  

    &:first-child {
      margin-top: 20px;
    } 
 
    &:last-child {
      margin-bottom: 5em;
    } 
  }

  .Separator {
    border-bottom: 3px solid $brown;
    width: 50%;
    margin: 1em; 
  }

  &.Visible {
    right: 0;
    min-width: 50%;
    width: auto;
  }
}
