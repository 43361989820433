@import '../../../variables';

.MainDashboardView {
  .MembershipSection {
    display: none;
  }

  @media (min-width: 320px) {
    .MembershipSection {
      display: flex;
    }
  }
}
